import React from 'react';

import MainLayout from 'layouts/main';
import SeoMetadata from 'utils/seo-metadata';

const NotFoundPage = () => (
  <MainLayout pageMetadata={SeoMetadata['404']}>
    <h1>404</h1>
    <p>Page not found</p>
  </MainLayout>
);

export default NotFoundPage;
